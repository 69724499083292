import React from "react";
import { Container } from "react-bootstrap";

function TrainingProgram({ title }) {
  return (
    <section class="training">
      <Container>
        <div className="mw900">
          <h2 class="heading-title sm text-left ml-0">{title}</h2>
          <p className="text-color">
            At 247 Legal Assist, we pride ourselves on providing our virtual
            assistants with the best possible resources. We believe this is
            crucial in creating a happy, effective, and long lasting staff for
            your firm.
          </p>
        </div>
        <h2 class="heading-title xs text-left ml-0 mt-4 mb-2">
          Benefits we provide for our Virtual Assistant!
        </h2>
        <ol className="training-benefits-list">
          <li>Basic-technology Certification</li>
          <li>Microsoft office certification</li>
          <li>Intake Proficiency Certification</li>
          <li>Case Management Certification</li>
          <li>Customer Service Certification</li>
          <li>Advanced Verbal and Written Communication certification</li>
        </ol>
      </Container>
    </section>
  );
}

export default TrainingProgram;
