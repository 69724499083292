import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import ProfilePic from './ProfilePic';
import LoadingButton from '../Loading/Loading';
import { useHistory } from 'react-router-dom';
import Error from '../Loading/Error';

const focuses = ['Legal', 'Medical', 'Other'];
const avails = ['Full Time', 'Part Time'];

function Add() {
  const history = useHistory();
  const [addState, setAddState] = useState('Add VA');
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Add VA to DB
  function addVA(e) {
    setAddState(<LoadingButton />);
    e.preventDefault();
    let name = e.target.name.value.toString();
    let email = e.target.email.value.toString();
    let focus = e.target.focus.value.toString();
    let avail = e.target.avail.value.toString();
    let pay = e.target.pay.value.toString();
    let rate = e.target.rate.value.toString();
    let specialties = e.target.specialties.value.toString();
    let languages = e.target.languages.value.toString();
    specialties = specialties.split(', ');
    specialties = specialties.map((s) => s.toLowerCase());
    languages = languages.split(', ');
    languages = languages.map((l) => l.toLowerCase());
    let video = handleVid(e.target.video.value);
    let bio = e.target.bio.value;
    let resume = e.target.resume.value;

    try {
      axios
        .put('https://58csx942wf.execute-api.us-west-2.amazonaws.com/live/va', {
          nameParam: name,
          emailParam: email,
          focusParam: focus,
          availabilityParam: avail,
          payParam: pay,
          rateParam: rate,
          specialtiesParam: specialties,
          languagesParam: languages,
          videoParam: video,
          bioParam: bio,
          resumeParam: resume,
          imageParam: image,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.responseCode === 200) {
            history.go(0);
          } else {
            setError(<Error error="something went wrong" />);
            setAddState('Add VA');
          }
        });
    } catch (err) {
      console.log(err);
      setError(<Error error="something went wrong" />);
    }
  }

  // Upload Image
  const [uploadState, setUploadState] = useState(<span>Upload</span>);
  const [image, setImage] = useState();
  const [imageReload, setImageReload] = useState();
  async function handleImage(e) {
    setUploadState(<LoadingButton />);
    var reader = new FileReader();
    var fileByteArray = [];

    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 360,
      useWebWorker: true,
    };

    try {
      // Compress image size
      const compressedFile = await imageCompression(imageFile, options);

      // Converts image file to bit array
      if (compressedFile) {
        reader.readAsArrayBuffer(compressedFile);
        reader.onloadend = async function (evt) {
          if (evt.target.readyState === FileReader.DONE) {
            var arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < array.length; i++) {
              fileByteArray.push(array[i]);
            }

            const imagePost = await axios.put(
              `https://58csx942wf.execute-api.us-west-2.amazonaws.com/live/image`,
              {
                imageBits: fileByteArray,
              }
            );
            console.log(imagePost);
            if (imagePost.data.responseCode === 200) {
              setImage(imagePost.data.imageUrl);
            } else {
              console.log('Oops something went wrong');
              setError(<Error error="something went wrong" />);
              setAddState('Add VA');
            }
            setUploadState(<div>Upload</div>);
            setImageReload((prevReload) => prevReload + 1);
          }
        };
      }
    } catch (err) {
      console.log('Image compression error', err);
      setError(<Error error="something went wrong" />);
      setAddState('Add VA');
    }
  }

  // Convert Youtube link to embed loom link
  function handleVid(url) {
    // id from the video url
    let videoId = url.substr(27, url.length - 1);
    return `https://www.loom.com/embed/${videoId}`;
  }

  return (
    <>
      <div class="admin-add-div">
        <button onClick={handleShow} class="admin-add">
          Add VA <i class="fad fa-plus-circle"></i>
        </button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new VA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
          <div>
            <form class="add-form" onSubmit={addVA}>
              <ProfilePic
                uploadState={uploadState}
                image={image}
                onChange={handleImage}
                imageReload={imageReload}
              />

              {/* Name */}
              <div>
                <input
                  type="text"
                  name="name"
                  className="auth-input"
                  placeholder="Full name*"
                  required
                  autofocus
                />
              </div>

              {/* Email */}
              <div>
                <input
                  type="email"
                  name="email"
                  className="auth-input"
                  placeholder="Email address*"
                  required
                  autofocus
                />
              </div>

              {/* Focus */}
              <div>
                <select name="focus" className="auth-input">
                  <option disabled selected value="">
                    {' '}
                    -- Select a Focus --{' '}
                  </option>
                  {focuses.map((focus) => {
                    return <option value={focus}>{focus}</option>;
                  })}
                </select>
              </div>

              {/* Avail */}
              <div>
                <select name="avail" className="auth-input">
                  <option disabled selected value="">
                    {' '}
                    -- Select Availability --{' '}
                  </option>
                  {avails.map((avail) => {
                    return <option value={avail}>{avail}</option>;
                  })}
                </select>
              </div>

              {/* Pay Rate */}
              <div>
                <input
                  type="text"
                  name="pay"
                  className="auth-input"
                  placeholder="Pay Rate*"
                  required
                  autofocus
                />
              </div>

              {/* Bill Rate */}
              <div>
                <input
                  type="text"
                  name="rate"
                  className="auth-input"
                  placeholder="Bill Rate*"
                  required
                  autofocus
                />
              </div>

              {/* Specialties */}
              <div>
                <p class="explain-text">
                  Seperate by commas and use the following options: Case
                  Manager, In-Take Specialist, Referral Manager, Client Advocacy
                </p>
                <input
                  type="text"
                  name="specialties"
                  className="auth-input"
                  placeholder="Specialties* (In-Take Specialist, Case Manager, etc)"
                  required
                  autofocus
                />
              </div>

              {/* Languages */}
              <div>
                <p class="explain-text">
                  Seperate by commas and use the following options: English,
                  Spanish
                </p>
                <input
                  type="text"
                  name="languages"
                  className="auth-input"
                  placeholder="Languages* (English, Spanish, etc)"
                  required
                  autofocus
                />
              </div>
              {/* Video */}
              <div>
                <input
                  type="text"
                  name="video"
                  className="auth-input"
                  placeholder="Video* (Loom video only)"
                  required
                  autofocus
                />
              </div>

              {/* Bio */}
              <div>
                <textarea
                  type="text"
                  name="bio"
                  class="auth-input va-bio"
                  placeholder="VA Bio* (2-3 sentences max)"
                  required
                  autofocus
                ></textarea>
              </div>

              {/* Resume */}
              <div>
                <input
                  type="text"
                  name="resume"
                  className="auth-input"
                  placeholder="Resume Link*"
                  required
                  autofocus
                />
              </div>

              <button type="submit">{addState}</button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Add;
