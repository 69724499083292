import React from 'react';
import { useForm } from '@formspree/react';
import { useHistory } from 'react-router-dom';

function Footer() {
  const [state, handleSubmit] = useForm('xgerkpjg');
  const history = useHistory();
  if (state.succeeded) {
    history.push('/success')
  }

  return (
    <div class="footer">
      <div class="footer-content">
        <p class="footer-title">
          <img class="footer-pic" src="../../images/deal.png" alt="footer" /> 247
          Legal Assist
        </p>
        <div>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              required
              class="sub-input"
              placeholder="Your Email*"
            />

            <button
              type="submit"
              disabled={state.submitting}
              class="sub-button"
            >
              Subscribe 
            </button>
          </form>
        </div>
        <span class="sub-text explain-text">
          By clicking subscribe, you agree to receive news and marketing emails
          from 247LegalAssist.
        </span>
        <br />
        <br />
        <br />
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <p class="footer-point">Our Address</p>
            <p>Los Angeles</p>
            <p>21777 Ventura Blvd. #263</p>
            <p>Woodland Hills CA 91364</p>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <p class="footer-point">Contact us</p>
            <p> support@247legalassist.com</p>
            <p> (310) 218-2564</p>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <p class="footer-point">Resources</p>
            <p>
              <a href="/terms">Terms and Conditions</a>
            </p>
            <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
