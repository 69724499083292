import React from 'react';

function How() {
  return (
    <div class="how">
      <p class="explain-text how-explain">It's so simple</p>

      <p class="how-title">How it Works</p>
      <div class="how-img-div">
        {' '}
        <img class="how-img" src="../images/group.png" alt="how" />
      </div>

      <div class="pointers">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="how-point">
              <div class="number">1</div>
              <p class="how-point-title">Meet our Team</p>
              <p class="how-point-explain explain-text">
                Join a demo with one of our Virtual Assistant experts who will
                go over the benefits of hiring a virtual assistant, share with
                you the certain roles and positions that a virtual assistant can
                fill, as well as show you potential candidates that are
                available.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="how-point">
              <div class="number">2</div>
              <p class="how-point-title">Meet Your Staff</p>
              <p class="how-point-explain explain-text">
                After you have chosen a candidate who is the right fit for your
                law firm, We will set up an interview that will allow you to
                discuss potential roles, expectations, and goals with your
                dedicated Virtual Assistant.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="how-point">
              <div class="number">3</div>
              <p class="how-point-title">Training & Onboarding</p>
              <p class="how-point-explain explain-text">
                Once you have completed your interview and have chosen your
                virtual assistant, our On-Boarding team will train your virtual
                assistant in your specific practice area to make sure they are
                completely prepared.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default How;
