/* eslint-disable no-useless-concat */
import React, {useEffect, useState} from 'react';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import './Blog.css';
import { Row, Col } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";

import {
    FacebookIcon,
    LinkedinIcon, 
    TwitterIcon
  } from "react-share";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
  } from "react-share";

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';


var contentful = require('contentful');

function BlogPost () {
    const [state, setState] = useState();
    const { slug } = useParams()


    useEffect(() => {
        var client = contentful.createClient({
            space: 'ygvn55oiqi9t',
            accessToken: '1yQ4vYnmhjRf8Tb5XKN_1MBNWVczHiaviRd50hhn_pw',
          });


          client.getEntries({
                content_type: 'metadataDescription',
                'fields.slug': slug.toString()}).then(function (entry) {
            // logs the field with ID title
            entry.items.forEach(function (entry) {
                setState(
                    <>
                        
                        <Helmet>
                            <title>{entry.fields.title}</title>
                            <meta charset="UTF-8" />
                            <meta name="description" content={entry.fields.metadataDescription} />
                            <meta name="keywords" content={entry.fields.tags} />
                        </Helmet>
    
                        <div className={'blog-post-wrapper'}>
                            <Row className={'head-data'}>
                                <Col lg={4} md={12} className={'blog-desktop-post-img'}>
                                    <img src={'https:'+entry.fields.image.fields.file.url} alt='' />
                                </Col>

                                <Col className={'head-text'} lg={7} sm={12}>
                                    <h1>
                                        {entry.fields.title}
                                    </h1>
                                </Col>

                                <Col className={'share-col blog-mobile-post-img'} lg={1} sm={12}>
                                    <FacebookShareButton url={'247legalassist.com/blog/post/'+slug}>
                                        <FacebookIcon size={32} round={true}/>
                                    </FacebookShareButton>
                                    <LinkedinShareButton url={'247legalassist.com/blog/post/'+slug}>
                                        <LinkedinIcon size={32} round={true}/>
                                    </LinkedinShareButton>
                                    <TwitterShareButton url={'247legalassist.com/blog/post/'+slug}>
                                        <TwitterIcon size={32} round={true}/>
                                    </TwitterShareButton>
                                </Col>


                                <Col lg={4} md={12} className={'blog-mobile-post-img'}>
                                    <img src={'https:'+entry.fields.image.fields.file.url} alt='' />
                                </Col>
                                
                                <Col className={'share-col blog-desktop-post-img'} lg={1} sm={12}>
                                    <FacebookShareButton url={'247legalassist.com/blog/post/'+slug}>
                                        <FacebookIcon size={32} round={true}/>
                                    </FacebookShareButton>
                                    <LinkedinShareButton url={'247legalassist.com/blog/post/'+slug}>
                                        <LinkedinIcon size={32} round={true}/>
                                    </LinkedinShareButton>
                                    <TwitterShareButton url={'247legalassist.com/blog/post/'+slug}>
                                        <TwitterIcon size={32} round={true}/>
                                    </TwitterShareButton>
                                </Col>
                            </Row>
                            
                            <div className={'content'}>
                                <h2>
                                    {entry.fields.description}
                                </h2>
                                <br />
    
                                <div dangerouslySetInnerHTML={{__html: documentToHtmlString(entry.fields.content)}}>
                                </div>
    
                                <h4 className={'tags'}>
                                    Tags: {entry.fields.tags}
                                </h4>
                            </div>
                            
                        </div>
                    </>
                )
            });
            
          });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


      
    return (
        <>
            <Navbar tag="AllVa" />
            {state}
            <Footer />
        </>
    )
}

export default BlogPost;
