import React from 'react';

function Loading() {
	return (
		<div class="spinner-border" role="status">
			<span class="sr-only">Loading...</span>
		</div>
	);
}

export default Loading;