import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import SubPageCover from "./SubPageCover";

import "./how-we-hire.css";
import Staff from "./Staff";
import TrainingProgram from "./TrainingProgram";
function HowWeHire() {
  return (
    <>
      <Navbar tag="24/7 Legal Assist" />
      <SubPageCover
        title="How we hire"
        explain="Our recruitment team handpicks the most qualified virtual assistant all over the world. Our virtual assistants are extensively interviewed and screened prior to becoming a certified virtual assistant, to ensure your law firm receives the highest-quality staff. Our large pool of virtual assistant candidates consist of English & Spanish speaking intake specialists, case managers, and customer support specialists with over 5 years of experience in the legal industry."
      />
      <Staff title="Why our staff is so effective!" />
      <TrainingProgram title="Certified training program" />
      <Footer />
    </>
  );
}

export default HowWeHire;
