import React from "react";
import { Link } from "react-router-dom";

function Navbar(props) {
  return (
    <nav className="navbar navbar-light bg-light">
      <div>
        <div className="navbar-brand">
          <Link to="/">
            <img src="../../images/logo.png" className="logo" alt="logo" />
          </Link>

          <span className="navbar-title">{props.tag}</span>
          <a href="tel:3102182564" className="blue phone-explain-text">
            {" "}
            (310-218-2564)
          </a>
        </div>
      </div>
      <div className="d-flex">
        <div>
          <a href="/blog" className="blog-navbar">Blog</a>
          <Link to="/how-we-hire"><button>How we hire</button></Link>
          {/* <a href="/search">
            <button className="candidates position-static ml-4">
              View Available Candidates
            </button>
          </a> */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
