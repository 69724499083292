import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

// Request
function Calendly() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} class="cta-button">
        Book Free Consultation{' '}
        <i class="fad fa-arrow-circle-right"></i>
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Free 30 Minute Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '600px' }}>
            <iframe
              src="https://calendly.com/virtual-assistant-team/legal-soft-solution-main"
              width="100%"
              height="100%"
              frameborder="0"
              title="calendly"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Calendly;
