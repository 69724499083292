import React from 'react';
import './admin.css';
import { useHistory } from 'react-router-dom';

function Lock() {
  const history = useHistory();

  function confirm(e) {
    var password = e?.target?.code?.value || "";
    if (password === '247legal321') {
      sessionStorage.setItem('_247_admin_', password);
      history.push('/admin-dash69');
    } else {
      alert('wrong password');
    }
  }

  return (
    <div class="auth general-container">
      <p class="auth-title">What's the admin passcode?</p>

      <form class="auth-form" onSubmit={confirm}>
        {/* code */}
        <div>
          <input
            type="text"
            name="code"
            class="auth-input"
            placeholder="Code"
            required
            autofocus
          />
        </div>
        <button class="auth-button">Confirm</button>
      </form>
    </div>
  );
}

export default Lock;
