import React from 'react';
import { Carousel } from 'react-bootstrap';

function Reviews(props) {
  return (
    <>
      <div class="reviews">
        <p class="video-title">Our Client Experience</p>

        <div class="videos">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="general-container video-review">
                <p class="review-name">Shawn Sassoones</p>
                <p class="review-firm">Sassoones Law</p>
                <iframe
                  class="review-video"
                  src="https://www.youtube.com/embed/pmNQWDqcefY"
                  title="vid"
                ></iframe>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="general-container video-review">
                <p class="review-name">Carlandos Scott</p>
                <p class="review-firm">Pinnacle Law</p>
                <iframe
                  class="review-video"
                  src="https://www.youtube.com/embed/QKUZ15rKfww"
                  title="vid"
                ></iframe>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 video-review">
              <div class="general-container">
                <p class="review-name">Sam Mollaei</p>
                <p class="review-firm">Mollaei Law</p>
                <iframe
                  class="review-video"
                  src="https://www.youtube.com/embed/-fwz1HYX8UI"
                  title="vid"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <p class="five-star">5 Star Reviews</p>
            <i class="star fas fa-star"></i>
            <i class="star fas fa-star"></i>
            <i class="star fas fa-star"></i>
            <i class="star fas fa-star"></i>
            <i class="star fas fa-star"></i>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12">
            <Carousel indicators={false}>
              <Carousel.Item>{props.reviewOne}</Carousel.Item>
              <Carousel.Item>{props.reviewTwo}</Carousel.Item>
              <Carousel.Item>{props.reviewThree}</Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="trusted-firms-div">
        <Carousel indicators={false}>
          <Carousel.Item>
            <div class="row">
              <div class="col-4">
                <img src="../images/sas.png" class="scroll-img" alt="ashoori" />
              </div>

              <div class="col-4">
                <img
                  src="../images/quill.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/robinson.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img
                  src="../images/evan.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>

              <div class="col-4">
                <img
                  src="../images/personal.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/boober.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img
                  src="../images/garreta.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>

              <div class="col-4">
                <img src="../images/kk.png" class="scroll-img" alt="ashoori" />
              </div>
              <div class="col-4">
                <img
                  src="../images/butt.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class="row">
              <div class="col-4">
                <img
                  src="../images/golden.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>

              <div class="col-4">
                <img src="../images/wolf.png" class="scroll-img" alt="ashoori" />
              </div>
              <div class="col-4">
                <img
                  src="../images/gomez.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="../images/held.png" class="scroll-img" alt="ashoori" />
              </div>

              <div class="col-4">
                <img
                  src="../images/polymath.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/whis.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="../images/hcw.png" class="scroll-img" alt="ashoori" />
              </div>

              <div class="col-4">
                <img
                  src="../images/cut.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/tofer.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div class="row">
              <div class="col-4">
                <img src="../images/lemle.png" class="scroll-img" alt="ashoori" />
              </div>

              <div class="col-4">
                <img
                  src="../images/mlg.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/krugerh.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="../images/rep.png" class="scroll-img" alt="ashoori" />
              </div>

              <div class="col-4">
                <img
                  src="../images/term.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/precht.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="../images/zanes.png" class="scroll-img" alt="ashoori" />
              </div>

              <div class="col-4">
                <img
                  src="../images/ben.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
              <div class="col-4">
                <img
                  src="../images/law-office.png"
                  class="scroll-img"
                  alt="ashoori"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}

export default Reviews;
