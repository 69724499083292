import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Hire from './Hire';
import Inactive from './Inactive';
import Available from './Available';

function VA(props) {
  const {
    name,
    email,
    focus,
    vaStatus,
    rate,
    pay,
    languages,
    specialties,
    employer,
    startDate,
    hours,
    availability,
    job,
    image,
    video,
    resume,
  } = props.va;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [specialtiesValue, setSpecialtiesValue] = useState();
  const [languagesValue, setLanguagesValue] = useState();

  useEffect(() => {
    if (languages.length > 0 && specialties.length > 0) {
      let languagesString = '';
      let specialtiesString = '';
      for (var i = 0; i < languages.length; i++) {
        if (i === languages.length - 1) {
          languagesString += languages[i];
        } else {
          languagesString += languages[i] + ', ';
        }
      }
      setLanguagesValue(languagesString);
      for (var j = 0; j < specialties.length; j++) {
        if (j === specialties.length - 1) {
          specialtiesString += specialties[j];
        } else {
          specialtiesString += specialties[j] + ', ';
        }
      }
      setSpecialtiesValue(specialtiesString);
    }
  }, [specialties, languages]);

  function setStatus() {
    if (vaStatus === 'available') {
      return (
        <p>
          Available {availability}{' '}
          <i class="blue fad fa-exclamation-circle"></i>
        </p>
      );
    }
    if (vaStatus === 'hired') {
      return (
        <p>
          Hired <i class="green fad fa-check-circle"></i>
        </p>
      );
    }
    if (vaStatus === 'inactive') {
      return (
        <p>
          Inactive <i class="red fad fa-times-circle"></i>{' '}
        </p>
      );
    } else {
      return <p>undefined</p>;
    }
  }

  function hired() {
    if (vaStatus === 'hired') {
      return true;
    } else {
      return false;
    }
  }

  function inactive() {
    if (vaStatus === 'inactive') {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'/profile/' + name}
            >
              {name}
            </a>{' '}
            {inactive() ? (
              <Available email={email} />
            ) : (
              <Inactive email={email} />
            )}{' '}
            <span class="explain-text">{setStatus()} </span>
            <img class="va-img" src={image} alt="va-img" />
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="vid-text"
                href={video}
              >
                Video
              </a>
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              class="vid-text"
              href={resume}
            >
              Resume
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-6">
              <p class="attribute">{email}</p>
            </div>
            <div class="col-6">
              <p class="attribute">{focus}</p>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="attribute col-6">
              {' '}
              ${pay}/hour <span class="explain-text">(pay)</span>
            </div>
            <div class="attribute col-6">
              {' '}
              ${rate}/hour <span class="explain-text">(rate)</span>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="attribute col-6">
              {languagesValue} <span class="explain-text">(languages)</span>
            </div>
            <div class="attribute col-6">
              {specialtiesValue} <span class="explain-text">(specialties)</span>
            </div>
          </div>
          <br />

          {hired() ? (
            <>
              <div class="row">
                <div class="attribute col-6">
                  {' '}
                  {employer} <span class="explain-text">(employer)</span>
                </div>
                <div class="attribute col-6">
                  {startDate} <span class="explain-text">(start date)</span>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="attribute col-6">
                  {job} <span class="explain-text">(position)</span>
                </div>

                <div class="attribute col-6">
                  {' '}
                  {hours} <span class="explain-text">(hours)</span>
                </div>
              </div>
            </>
          ) : null}

          {inactive() || hired() ? <p></p> : <Hire email={email} />}
        </Modal.Body>
      </Modal>
      <div onClick={handleShow} class="a-data">
        <div class="row">
          <div class="col-3">{name}</div>
          <div class="col-3">{email}</div>
          <div class="col-3">{focus}</div>
          <div class="col-3">{setStatus()}</div>
        </div>
      </div>
    </div>
  );
}

export default VA;
