import React from "react";
import Header from "./Header";
import Add from "./Add";
import VAs from "./VAs";

class Admin extends React.Component {
  componentDidMount() {
    var token = sessionStorage.getItem("_247_admin_");
    if (!token) {
      this.props.history.push("/admin");
    }
  }

  render() {
    return (
      <>
        <Header />

        <div class="admin">
          <Add />
          <VAs />
        </div>
      </>
    );
  }
}

export default Admin;
