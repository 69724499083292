import React from "react";
import { Container, Image } from "react-bootstrap";

function InfoSection() {
  return (
    <div
      className="info-section"
      style={{
        backgroundImage: "url('/images/infobg.jpg')",
      }}
    >
      <Container>
        <div className="info-section--content">
          <figure>
            <Image src="/images/info-image.jpg" />
          </figure>
          <div className="info-section--card">
            <div className="info-section--card-content">
              Today, a law firm’s success is linked directly to its ability to
              scale and expand. Whether you’re looking for short term or
              long-term staffing requirements you’ll need a partner who
              understands legal staff recruiting.
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default InfoSection;
