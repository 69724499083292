import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './profile.css';
import Request from './Request';
import Navbar from '../Home/Navbar';
import Helmet from "react-helmet"

function Profile(props) {
  const namePassed = props.match.params.name;
  const [name, setName] = useState();
  const [bio, setBio] = useState();
  const [video, setVideo] = useState();
  const [languages, setLanguages] = useState([]);
  const [availability, setAvailability] = useState();
  const [resume, setResume] = useState();
  const [focus, setFocus] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [meta, setMeta] = useState();

  useEffect(() => {
    if (namePassed.length > 0) {
      axios
        .get('https://58csx942wf.execute-api.us-west-2.amazonaws.com/live/va')
        .then((res) => {
          let VAdata = res.data.Items;
          let VAProfile = VAdata.filter(function (va) {
            return va.name === namePassed;
          });
          console.log(res.data.Items);
          if (VAProfile.length > 0) {
            let VA = VAProfile[0];
            setName(VA.name);
            setBio(VA.bio);
            setVideo(VA.video);
            setResume(VA.resume);
            setAvailability(VA.availability);
            setLanguages(VA.languages);
            setFocus(VA.focus);
            setSpecialties(VA.specialties);
            if (VA.availability ==='inactive') {
              setMeta(<Helmet><meta name="robots" content="noindex" /></Helmet>);
            }
          } else {
            setMeta();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [namePassed]);

  function changeFocus() {
    if (focus === 'Legal') {
      return (
        <p>
          <i class="blue fad fa-gavel"></i> Professional Experience
        </p>
      );
    }

    return (
      <p>
        <i class="red fad fa-notes-medical"></i> Medical Experience
      </p>
    );
  }

  var list = window.$shortVAList;
  function request() {
    if (list.length === 0) {
      list.push('Requesting: ' + name);
    }
  }

  return (
    <div>
      <Navbar tag="247 Legal Assist" />
      {/* A Candidate */}
      <head>
        {meta}
      </head>
    
        <p class="virtual-agent-profile">{name}'s Profile</p>
        <div class="a-va-profile">
        <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
              <iframe
                title="profile"
                class="profile-va-video"
                src={video}
              ></iframe>
              <p class="candidate-name">
                {' '}
                <i class="va-online fas fa-circle" /> {name}
                <span class="candidate-languages">
                  {/* show languages in the right format */} (
                  {languages.map((language) => {
                    if (languages.indexOf(language) === languages.length - 1) {
                      return language;
                    }
                    return language + ', ';
                  })}
                  )
                </span>
              </p>
              <p class="candidate-specialty">{changeFocus()}</p>
              <a
                class="candidate-specialty"
                target="_blank"
                rel="noopener noreferrer"
                href={resume}
              >
                <i class="fad fa-file"></i> Resume
              </a>
              <br />
              <br />

              {specialties.map((specialty) => (
                <button class="subject">{specialty}</button>
              ))}
           </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="va-statement-div">
                <h5>
                  About {name}{' '}
                  <span class="explain-text">(available {availability})</span>
                </h5>
                <p class="profile-bio">{bio}</p>
              </div>

              <div onClick={request}>
                <Request />
              </div>
              <p class="certificate-profile">
                {' '}
                <i class="check fas fa-check-circle"></i>
                Certified
              </p>
            </div>{' '}
        </div>
      </div>
    </div>
  );
}

export default Profile;
