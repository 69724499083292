import React from 'react';

function Company(props) {
  return (
    <div class="col-3">
      <img class="trusted-img" src={props.img} alt ="comp" />
    </div>
  );
}

function Trusted(props) {
  return (
    <div class="trusted">
      <p class="trusted-title">
        {props.firstTitle} <span class="blue">{props.highlightTitle}</span> {' '}
        {props.lastTitle}
      </p>
      <div class="row">
        <Company img={props.partnerOne} alt ="company"/>
        <Company img={props.partnerTwo} alt ="comp2" />
        <Company img={props.partnerThree} alt ="comp3"/>
        <Company img={props.partnerFour} alt ="comp4"/>
      </div>
    </div>
  );
}

export default Trusted;
