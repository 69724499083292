import React, { useState } from 'react';
import axios from 'axios';
import Loading from '../Loading/Loading';
import { useHistory } from 'react-router-dom';

function Hire(props) {
  const history = useHistory();
  const [buttonState, setButtonState] = useState('Hire');

  function hireVA(e) {
    setButtonState(<Loading/>);
    e.preventDefault();
    axios
      .post('https://58csx942wf.execute-api.us-west-2.amazonaws.com/live/va', {
        emailParam: props.email,
        employerParam: e.target.employer.value,
        salesAgentParam: e.target.agent.value,
        jobParam: e.target.job.value,
        startDateParam: e.target.date.value,
        hoursParam: e.target.hours.value,
        vaStatusParam: 'hired',
      })
      .then(() => history.go(0));
  }
  return (
    <form onSubmit={hireVA}>
      {/* Employer */}
      <div>
        <input
          type="text"
          name="employer"
          className="auth-input"
          placeholder="Employer*"
          required
          autofocus
        />
      </div>

       {/* Sales Agent */}
       <div>
        <input
          type="text"
          name="agent"
          className="auth-input"
          placeholder="Sales Agent*"
          required
          autofocus
        />
      </div>

      {/* Job */}
      <div>
        <input
          type="text"
          name="job"
          className="auth-input"
          placeholder="Job*"
          required
          autofocus
        />
      </div>

      {/* Start Date */}
      <div>
        <input
          type="start"
          name="date"
          className="auth-input"
          placeholder="Start Date*"
          required
          autofocus
        />
      </div>

      {/* Hours */}
      <div>
        <input
          type="start"
          name="hours"
          className="auth-input"
          placeholder="Hours*"
          required
          autofocus
        />
      </div>

      <button type="submit">{buttonState}</button>
    </form>
  );
}

export default Hire;
