import React from 'react';



function Header() {
  return (
    <nav class="navbar navbar-light bg-light">
      <a class="navbar-brand" href="/">
        <img src="../images/logo.png" class="logo" alt="logo" />
        <span class="navbar-title">Admin</span>
      </a>
    </nav>
  );
}

export default Header;
