import React from 'react';
import './notfound.css';
import Navbar from './Navbar';
import Helmet from "react-helmet"
import {Col, Row, Container} from 'react-bootstrap';

function NotFound () {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Navbar />

            <Container>
                <Row className="notfound">
                    <Col lg={6}>
                        <img src="../images/404notfound.svg" alt="" />
                    </Col>
                    <Col lg={6}>
                        <h1>Oops!</h1>
                        <h2>
                            We can’t seen to find the page you are looking for.
                        </h2>
                        <a href="/">Go Back</a>
                    </Col>
                </Row>
            </Container>    
        </>
    )
}

export default NotFound;