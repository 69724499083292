import React from 'react';

function ProfilePic(props) {
  return (
    <div class="profile-pic">
      <div class="settings-header">
        <h5> Profile Picture* </h5>
        <p class="explain-text">
          <i class="fas fa-exclamation-circle"></i> Please make the image square
          and PNG before uploading
        </p>
      </div>
      <img
        class="settings-image"
        src={
          props.image && props.image.length
            ? `${props.image}?${props.imageReload}`
            : 'https://image.flaticon.com/icons/svg/64/64572.svg'
        }
        alt="settings"
      />

      <label class="upload" for="upload-photo">
        {props.uploadState}
      </label>
      <input
        id="upload-photo"
        type="file"
        accept="image/png"
        class="settings-upload"
        onChange={props.onChange}
      />
    </div>
  );
}

export default ProfilePic;
