import React from "react";
import { Container } from "react-bootstrap";
function SubPageCover(props) {
  return (
    <div class="subpage-cover">
      <Container>
        <h2>{props.title}</h2>
        <p>{props.explain}</p>
      </Container>
    </div>
  );
}

export default SubPageCover;
