import React from 'react';
import Top from './Top';
import './home.css';
import Navbar from './Navbar';
import Trusted from './Trusted';
import How from './How';
import Monitor from './Monitor';
import Reviews from './Reviews';
import Footer from './Footer';
import Benefits from './Benefits';
import Managing from './Managing';
import InfoSection from './InfoSection';

function Law() {
  return (
    <>
      <Navbar tag="24/7 Legal Assist" />
      <Top
        img="../images/247legalassist.png"
        title="Hire Virtual Staff for your Law Firm"
        explain="Starting at $10/hour"
      />
      <Trusted
        firstTitle="As Featured"
        highlightTitle="In"
        partnerOne="../images/summit.png"
        partnerTwo="../images/lawyerpreneur.png"
        partnerThree="../images/james-publishing.png"
        partnerFour="../images/Filevine.png"
      />
      <InfoSection />
      <Benefits title="Benefits of Hiring a Virtual Assistant for you Law Firm" />
      <How />
      <Managing />
      <Monitor explain="There are many benefits to hiring virtual staff for your law firm, here's a few: " />
      <Reviews
        reviewOne={
          <p class="review">
            "Working with Legal Soft Solution has been one of the best decisions
            I've made for my practice. I cannot overstate how tremendously
            helpful Hamid Kohan has been for our practice. If you are looking to
            grow your law firm, automate your process, and scale, then you are
            in the right place at Legal Soft Solution! Thank you Hamid!!!"
            -Michael Ashoori <i class="green fas fa-check-circle"></i>
          </p>
        }
        reviewTwo={
          <p class="review">
            "Hamid and co are amazing! I feel very fortunate that I came across
            them and took a chance. Every member of every division of their
            company has exceeded my highest expectations. From the first call I
            had with Hamid, I knew that I had to tap into his knowledge and take
            his advice! I have been working with them for several months and
            couldn't be happier. This review was long overdue!" -Aaron Siddique{' '}
            <i class="green fas fa-check-circle"></i>
          </p>
        }
        reviewThree={
          <p class="review">
            "Hamid and his team possess a unique vision in the legal tech
            industry which reflects their dedication, professionalism, and
            determination in helping law firms of any size or background. They
            know what law firms are lacking in this new age of technology and
            lead them into the prospects of being ahead in a highly competitive
            field." -Behrad Brumand <i class="green fas fa-check-circle"></i>
          </p>
        }
      />
      <Footer />
    </>
  );
}

export default Law;
