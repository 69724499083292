import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Request
function Request() {
  const history = useHistory();

  var list = window.$shortVAList;

  const [state, handleSubmit] = useForm('xgerkpjg');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (state.succeeded) {
    history.push('/success');
  }

  return (
    <>
      <button onClick={handleShow} class="request-profile-va">
        Request Interview
      </button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="request">
            <p class="request-title">To reset list, simply refresh page!</p>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  class="auth-input"
                  placeholder="VA Names*"
                  id="va"
                  type="text"
                  name="va"
                  value={list}
                />
              </div>
              <div>
                <input
                  class="auth-input"
                  placeholder="Email*"
                  id="email"
                  type="email"
                  name="email"
                />
              </div>
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <div>
                <input
                  class="auth-input"
                  placeholder="Phone Number*"
                  id="phone"
                  type="phone"
                  name="phone"
                />
              </div>
              <div>
                <textarea
                  class="auth-input"
                  placeholder="How can we help?"
                  id="message"
                  name="message"
                />
              </div>
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <button
                class="request-button"
                type="submit"
                disabled={state.submitting}
              >
                Submit
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Request;
