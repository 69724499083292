import React from 'react';

function NothingFound() {
	return (
		<div class="nothing-found">
			<h4>
				Nothing Found <i class="far fa-sad-tear"></i>{' '}
			</h4>
		</div>
	);
}

export default NothingFound;