/* eslint-disable no-useless-concat */
import React from 'react';
import { Card } from 'react-bootstrap';

import {
    FacebookIcon,
    LinkedinIcon, 
    TwitterIcon
  } from "react-share";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
  } from "react-share";


function BlogCards (props) {

    return (
        <Card className={'blog-cards'}>
            <Card.Img variant="top" src={props.image} className={'blog-card-image'} />
            <Card.Body>
                <Card.Title><h2>{props.title}</h2></Card.Title>
                <Card.Text>
                    <div className={'card-content'}>
                        {props.content}
                    </div>
                </Card.Text>

                <div className={'card-bottoms'}>
                    <a href={'/blog/post/'+props.more}>
                        <button className="candidates position-static ml-4">
                            Read More
                        </button>
                    </a>

                    <div className={'share-div'}>
                            <h6>Share: </h6>

                            <FacebookShareButton url={'247legalassist.com/blog/post/'+props.more}>
                                <FacebookIcon size={32} round={true}/>
                            </FacebookShareButton>
                            <LinkedinShareButton url={'247legalassist.com/blog/post/'+props.more}>
                                <LinkedinIcon size={32} round={true}/>
                            </LinkedinShareButton>
                            <TwitterShareButton url={'247legalassist.com/blog/post/'+props.more}>
                                <TwitterIcon size={32} round={true}/>
                            </TwitterShareButton>
                    </div>
                </div>
                
                
                
            </Card.Body>
        </Card>

    )
}

export default BlogCards;