import React, { useState } from 'react';
import Video from './Video';

window.$shortVAList = [];

function Candidate(props) {
  var shortVAList = window.$shortVAList;
  const {
    name,
    languages,
    focus,
    bio,
    specialties,
    video,
    image,
    resume,
    availability
  } = props.candidate;

  const [shortList, setShortList] = useState(
    <button onClick={addToList} class="book request-va">
      Request Interview
    </button>
  );

  const [certificate, setCertificate] = useState(
    <p class="certificate">
      {' '}
      <i class="check fas fa-check-circle"></i>
      Certified
    </p>
  );

  function setFocus() {
    if (focus === 'Legal') {
      return (
        <p>
          <i class="blue fad fa-gavel"></i> Professional Experience
        </p>
      );
    }

    return (
      <p>
        <i class="red fad fa-notes-medical"></i> Professional Experience
      </p>
    );
  }

  // Add to Short List
  function addToList() {
    setCertificate(null);
    setShortList(
      <p class="added-va ">
        Added to List <i class="check fas fa-check-circle"></i>
        <p class="explain-text">
          Click 'Request' Above to schedule an interview
        </p>
      </p>
    );

    shortVAList.push(' ' + name);
  }

  return (
    <div>
      {/* A Candidate */}
      <div>
        <div class="a-va">
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
              <Video video={video} image={image} />

              <p class="candidate-name">
                {' '}
                <i class="va-online fas fa-circle" />{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'/profile/' + name}
                >
                  {name}
                </a>
                <span class="candidate-languages">
                  {/* show languages in the right format */} (
                  {languages.map((language) => {
                    if (languages.indexOf(language) === languages.length - 1) {
                      return language;
                    }
                    return language + ', ';
                  })}
                  )
                </span>
              </p>
              <p class="candidate-specialty">{setFocus()}</p>
              <a
                class="candidate-specialty"
                target="_blank"
                rel="noopener noreferrer"
                href={resume}
              >
                <i class="fad fa-file"></i> Resume
              </a>
              <br />
            </div>

            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="va-statement-div">
                <h5>
                  About {name}{' '}
                  <span class="explain-text">(available {availability})</span>
                </h5>
                <p class="va-statement">{bio}</p>
              </div>
              {specialties.map((specialty) => (
                <button class="subject">{specialty}</button>
              ))}
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              {' '}
              {shortList}
              {certificate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Candidate;
