import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

function Managing() {
  return (
    <section className="managing light-bg">
      <Container>
        <Row className="align-items-center">
          <Col md="6">
            <Image src="/images/managing-illustration.svg" fluid />
          </Col>
          <Col md="6">
            <div className="managing-content">
              <h3 className="heading-title text-left sm">Managing</h3>
              <p className="text-color">
                Our performance management team is dedicated to managing your
                virtual staff. Our team offers a full-time, In-House customer
                support center to address any questions or concerns your law
                firm might have, and are in constant contact with your virtual
                staff in order to ensure your staff is properly managed.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Managing;
