import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home/Home";
import Law from "./Home/Law";
import Search from "./Search/Search";
import Lock from "./Admin/Lock";
import Admin from "./Admin/Admin";
import Success from "./Home/Success";
import Profile from "./Profile/Profile";
import HowWeHire from "./HowWeHire/HowWeHire";

import BlogHome from './Blog/BlogHome';
import BlogPost from './Blog/BlogPost';
import NotFound from './Home/NotFound';

function Main() {
  return (
    <Switch>
      {/* Landing pages */}
      <Route exact path="/" component={Law}></Route>
      <Route exact path="/home" component={Home}></Route>
      <Route exact path="/success" component={Success}></Route>
      <Route exact path="/how-we-hire" component={HowWeHire}></Route>

      {/* Search */}
      {/* <Route exact path="/search" component={Search}></Route> */}
      <Route exact path="/request" component={Request}></Route>

      {/* Profile */}
      <Route exact path="/profile/:name" component={Profile} />

      {/* Admin */}
      <Route exact path="/admin" component={Lock}></Route>
      <Route exact path="/admin-dash69" component={Admin}></Route>

      {/* Blog */}
      <Route exact path="/blog" component={BlogHome} />
      <Route exact path="/blog/post/:slug" component={BlogPost} />

      <Route path='*' component={NotFound}/>

    </Switch>
  );
}

export default Main;
