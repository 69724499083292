import React from 'react';
import Top from './Top';
import './home.css';
import Navbar from './Navbar';
import Trusted from './Trusted';
import How from './How';
import Monitor from './Monitor';
import Reviews from './Reviews';
import Footer from './Footer';

function Home() {
  return (
    <>
      <Navbar tag="Blog" />
      <Top img="../images/main.png" title = "Hire Virtual Staff" explain="for whatever you need" />
      <Trusted
        firstTitle="Trusted by"
        highlightTitle="100s"
        lastTitle="of Companies"
        partnerOne="../images/ansari.png"
        partnerTwo="../images/legal-funnel-medium.png"
        partnerThree="../images/legalsoft.png"
        partnerFour="../images/calendly.png"
      />
      <How />
      <Monitor />
      <Reviews
        reviewOne={
          <p class="review">
            "I was able to hire 2 VAs for lead generation and I couldn't be
            happer with the decision. Took so many tedious tasks off my hands"
            -Abby <i class="green fas fa-check-circle"></i>
          </p>
        }
        reviewTwo={
          <p class="review">
            "Working with AllVA VAs has been a pleasure. They pre-qualify them
            so they are extremely good when you interview them. Also, they are
            just so sharp and great at english" -Chris{' '}
            <i class="green fas fa-check-circle"></i>
          </p>
        }
        reviewThree={
          <p class="review">
            "Thank you for giving me 20+ hours a week to do what I love. My
            LawFirm is now much more automated with 3 hires, I love it" -Ghasem{' '}
            <i class="green fas fa-check-circle"></i>
          </p>
        }
      />
      <Footer />
    </>
  );
}

export default Home;
