import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Calendly from './Calendly';

function HomeVideo() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div onClick={handleShow} className="play-video">
        <i className="fad fa-play-circle"></i>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>How it works</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            className="home-video"
            src="https://www.youtube.com/embed/SgO3S5sBlEo"
            title="vid"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

function Top(props) {
  return (
    <div className="top">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <p className="top-title">
            {' '}
            <span className="hire-best">{props.title} </span>{' '}
            <span className="whatever-need blue">{props.explain}</span>
          </p>

          <p className="row checklist">
            <div className="col-4">
              <i className="blue fad fa-check-circle"></i> Virtual
            </div>
            <div className="col-4">
              <i className="blue fad fa-check-circle"></i> Affordable
            </div>
            <div className="col-4">
              <i className="blue fad fa-check-circle"></i> Effective
            </div>
          </p>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <HomeVideo />
          <img className="top-img" src={props.img} alt="main" />
          <p className="staff-explain-text">Meet an In-Take Specialist</p>
        </div>

        <Calendly />
      </div>
    </div>
  );
}

export default Top;
