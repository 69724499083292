import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BenefitCard from "../shared/BenefitCard";
const BENEFITS_DATA = [
  {
    id: 1,
    icon: "/images/icons/health-insurance.png",
    title: "Health Insurance",
  },
  {
    id: 2,
    icon: "/images/icons/high-speed.png",
    title: "High-Speed Internet",
  },
  {
    id: 3,
    icon: "/images/icons/laptop.png",
    title: "Communication and Computer Devices",
  },
  {
    id: 4,
    icon: "/images/icons/passport.png",
    title: "Immigration Services",
  },
];
/**
 * Benefit section for home screen
 *
 * @param {*} { title }
 */

function Staff({ title }) {
  return (
    <div className="light-bg py-4">
      <section class="staff">
        <Container>
          <div className="mw900">
            <h2 class="heading-title sm text-left ml-0">{title}</h2>
            <p className="text-color">
              At 247 Legal Assist, we pride ourselves on providing our virtual
              assistants with the best possible resources. We believe this is
              crucial in creating a happy, effective, and long lasting staff for
              your firm.
            </p>
          </div>
          <br/>
          <br/>
          <br/>

          <h2 class="heading-title sm text-left ml-0">Benefits</h2>

          <Row>
            {BENEFITS_DATA.map((abenefit) => (
              <Col md="3" key={abenefit.id}>
                <BenefitCard icon={abenefit.icon} title={abenefit.title} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Staff;
