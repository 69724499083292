import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BenefitCard from "../shared/BenefitCard";
const BENEFITS_DATA = [
  {
    id: 1,
    icon: "/images/icons/easy-hire.png",
    title: "Easy to Hire and Manage",
  },
  {
    id: 2,
    icon: "/images/icons/cost.png",
    title: "Fixed Cost",
  },
  {
    id: 3,
    icon: "/images/icons/no-office.png",
    title: "No office equipment requirements",
  },
  {
    id: 4,
    icon: "/images/icons/no-tax.png",
    title: "No insurance or taxes",
  },
  {
    id: 5,
    icon: "/images/icons/no-eb.png",
    title: "No Employment Benefits",
  },
  {
    id: 6,
    icon: "/images/icons/case-cost.png",
    title: "Can be considered as Case Cost",
  },
  {
    id: 7,
    icon: "/images/icons/monthly-payment.png",
    title: "1099 Monthly Payment",
  },
  {
    id: 8,
    icon: "/images/icons/contract.png",
    title: "No Long Term Contract",
  },
];
/**
 * Benefit section for home screen
 *
 * @param {*} { title }
 */

function Benefits({ title }) {
  return (
    <section class="benefits">
      <h2 class="heading-title">{title}</h2>
      <Container>
        <Row>
          {BENEFITS_DATA.map((abenefit) => (
            <Col md="3" key={abenefit.id}>
              <BenefitCard icon={abenefit.icon} title={abenefit.title} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Benefits;
