import React, { useState } from 'react';
import Loading from '../Loading/Loading';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function Inactive(props) {
  const history = useHistory();
  const [buttonState, setButtonState] = useState('Mark Inactive');
  function markInactive(e) {
    e.preventDefault();
    setButtonState(<Loading />);
    axios
      .post('https://58csx942wf.execute-api.us-west-2.amazonaws.com/live/va', {
        emailParam: props.email,
        employerParam: '',
        jobParam: '',
        startDateParam: '',
        hoursParam: '',
        salesAgentParam: '',
        vaStatusParam: 'inactive',
      })
      .then(() => history.go(0));
  }
  return (
    <button class="inactive" onClick={markInactive}>
      {buttonState}
    </button>
  );
}

export default Inactive;
