import React from "react";

/**
 *
 *
 * @param {string} {title}
 * @param {string} {icon} icon image src
 * @return {*}
 */
function BenefitCard({ icon, title }) {
  return (
    <div className="benefit-card">
      <div className="benefit-card--icon">
        <img src={icon} alt={title} />
      </div>
      <h6 className="benefit-card--title mt-3">{title}</h6>
    </div>
  );
}

export default BenefitCard;
