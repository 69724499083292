import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VA from './VA';
import Search from './Search';
import NothingFound from '../Loading/Nothing';
import Loading from '../Loading/Loading';

function VAs() {
  const [allVAs, setAllVAs] = useState([]);
  const [VAs, setVAs] = useState([]);
  const [showState, setShowState] = useState(<Loading />);
  useEffect(() => {
    axios
      .get('https://58csx942wf.execute-api.us-west-2.amazonaws.com/live/va')
      .then((res) => {
        console.log(res.data.Items);
        let VAdata = res.data.Items;
        let VAdataSorted = VAdata.sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        });
        setVAs(VAdataSorted);
        setAllVAs(VAdataSorted);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (VAs.length > 0) {
      setShowState(VAs.map((va) => <VA va={va} />));
    }
  }, [VAs]);

  // Pick type of VA
  const [allClass, setAllClass] = useState('admin-picked');
  const [availClass, setAvailClass] = useState('admin-pick');
  const [hiredClass, setHiredClass] = useState('admin-pick');
  const [inactiveClass, setInactiveClass] = useState('admin-pick');

  function setItAllVAs() {
    setAllClass('admin-picked');
    setAvailClass('admin-pick');
    setHiredClass('admin-pick');
    setInactiveClass('admin-pick');

    setVAs(allVAs);
  }

  function availVAs() {
    setAllClass('admin-pick');
    setAvailClass('admin-picked');
    setHiredClass('admin-pick');
    setInactiveClass('admin-pick');

    let availOnes = allVAs.filter(function (va) {
      return va.vaStatus === 'available';
    });

    setVAs(availOnes);
  }

  function hiredVAs() {
    setAllClass('admin-pick');
    setAvailClass('admin-pick');
    setHiredClass('admin-picked');
    setInactiveClass('admin-pick');

    let hiredOnes = allVAs.filter(function (va) {
      return va.vaStatus === 'hired';
    });

    setVAs(hiredOnes);
  }

  function inactiveVAs() {
    setAllClass('admin-pick');
    setAvailClass('admin-pick');
    setHiredClass('admin-pick');
    setInactiveClass('admin-picked');

    let inactiveOnes = allVAs.filter(function (va) {
      return va.vaStatus === 'inactive';
    });

    setVAs(inactiveOnes);
  }

  // Search Funcationality
  const [searchState, setSearch] = useState('');

  function searchOnChange(event) {
    setSearch(event.target.value);
  }

  function findVA(e) {
    e.preventDefault();

    var results = [];

    var search = searchState.toString().toLocaleLowerCase();

    if (searchState === '') {
      setShowState(VAs.map((va) => <VA va={va} />));
      return;
    }
    for (let t = 0; t < VAs.length; t++) {
      var name = VAs[t].name.toLowerCase();
      var email = VAs[t].email.toLowerCase();
      var employer = 'asdsd';
      if (VAs[t].employer != null) {
        employer = VAs[t].employer.toLowerCase();
      }

      if (
        name.includes(search) ||
        email.includes(search) ||
        employer.includes(search)
      ) {
        results.push(VAs[t]);
      }
    }
    if (results.length > 0) {
      setShowState(results.map((va) => <VA va={va} />));
      return;
    }

    setShowState(<NothingFound />);
  }

  return (
    <>
      <Search
        searchOnClick={findVA}
        searchOnChange={searchOnChange}
        inputValue={searchState}
      />
      <div class="row">
        <div class="col-3">
          <button onClick={setItAllVAs} class={allClass}>
            All
          </button>
        </div>
        <div class="col-3">
          <button onClick={availVAs} class={availClass}>
            Available
          </button>
        </div>
        <div class="col-3">
          <button onClick={hiredVAs} class={hiredClass}>
            Hired
          </button>
        </div>
        <div class="col-3">
          <button onClick={inactiveVAs} class={inactiveClass}>
            Inactive
          </button>
        </div>
      </div>
      <div class="all-students-container">
        <div class="all-labels">
          <div class="row">
            <div class="col-3">
              <i class="fal fa-user"></i> Name
            </div>
            <div class="col-3">
              <i class="far fa-envelope-open"></i> Email
            </div>
            <div class="col-3">
              {' '}
              <i class="fal fa-clipboard-user"></i> Focus
            </div>
            <div class="col-3">
              {' '}
              <i class="far fa-chess-knight"></i> Status
            </div>
          </div>
        </div>

        {showState}
      </div>
    </>
  );
}

export default VAs;
