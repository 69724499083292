import React, {useEffect, useState} from 'react';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import BlogCards from './BlogCards';
import {Row} from 'react-bootstrap';
import './Blog.css';
import Helmet from "react-helmet"

var contentful = require('contentful');

function BlogHome () {
    const [state, setState] = useState();

    useEffect(() => {
        var client = contentful.createClient({
            space: 'ygvn55oiqi9t',
            accessToken: '1yQ4vYnmhjRf8Tb5XKN_1MBNWVczHiaviRd50hhn_pw',
          });
    
        client.getEntries().then(function (entries) {
        // log the title for all the entries that have it
        entries.items.forEach(function (entry) {
            setState(oldstate => [oldstate, <BlogCards image={'https:'+entry.fields.image.fields.file.url} title={entry.fields.title} content={entry.fields.description} more={entry.fields.slug} id={entry.sys.id}/>])
        });
        });
    }, []);


    return (
        <>
            <Helmet>
                <title>247 Legal Assist - Blog</title>
                <meta charset="UTF-8" />
                <meta name="description" content='Blog' />
                {/* <meta name="keywords" content={entry.fields.tags} /> */}
            </Helmet>
            <Navbar tag="Blog" />
            <h1 className={'h1-SEO'}>247 Legal Assist - Blog</h1>
            <Row className={'blog-home'}>
                {state}
            </Row>
        
            <Footer />
        </>
    )
}

export default BlogHome;
