import React from 'react';
import Calendly from './Calendly';

function MonitorPoint(props) {
  return (
    <div class="col-md-4 col-lg-4 col-sm-12">
      <div class="benefit">
        <div>
          <p class="blue benefit-title">{props.title}</p>
          <p class="benefit-explain">{props.explain}</p>
        </div>
        <br/>
        <div>
          <img class="benefit-img" src={props.img} alt="benefit" />
        </div>
      </div>
    </div>
  );
}

function Monitor() {
  return (
    <div class="benefits">
      <p class="benefits-title">How We Monitor the Staff</p>
      <p class="benefits-explain">
        Our Staff Management tool is required to be installed on all virtual
        assistants devices, in order to effectively monitor your virtual
        assistant. This innovative tool captures a series of
        screenshots of your virtual assistants device every 10 minutes,
        implements URL tracking to ensure that agents are working efficiently on
        your tasks, as well as sending daily and weekly reports with all
        completed tasks.{' '}
      </p>
      <div class="row">
        <MonitorPoint
          title="Screenshots"
          explain="This innovative tool captures a series of screenshots of your virtual assistants device every 10 minutes"
          img="../images/screenshot.png"
        />
        <MonitorPoint
          title="URL Tracking"
          explain="Our team monitors agent URLs to ensure that agents are working efficiently on your tasks."
          img="../images/tracking.png"
        />
        <MonitorPoint
          title="Daily Reports"
          explain="Our team sends daily and weekly reports with all completed tasks."
          img="../images/report.png"
        />
      </div>

      <Calendly />
    </div>
  );
}

export default Monitor;
