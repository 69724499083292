import React from 'react';

function Search(props) {
	return (
		<div class="search-container-big">
			<div class="search-div-big">
				<form onSubmit={props.searchOnClick}>
					<input
						class="search-big"
						type="text"
						id="search"
						placeholder="Search for a user..."
						value={props.inputValue}
						onChange={props.searchOnChange}
					/>

					<button type="submit" class="submit" name="search">
						<i class="search-icon fas fa-search"></i>
					</button>
				</form> 
			</div>
		</div>
	);
}

export default Search;