import React from 'react';
import Navbar from './Navbar';

function Success() {
  return (
    <div>
      <Navbar />
      <div class="success">
        We got your request, we will be with you shortly
        <img src="../images/success.png" alt="success" class="success-img" />
      </div>
    </div>
  );
}

export default Success;
